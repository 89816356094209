import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="psionic-fist"></a><h3>PSIONIC FIST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80454.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d6.
    <a id="psionic-fist-requirements"></a><h6>Requirements</h6>
To qualify to become a Psionic Fist, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span> +4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill:</span> <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> 9
ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feat:</span> <a href="psionicFeats.html#wild-talent" style={{
        "color": "rgb(87, 158, 182)"
      }}>Wild
Talent</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Still mind
class feature.</p>
    <h6>Class Skills</h6>
The Psionic Fist&#8217;s class skills (and the key ability for each skill)
are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a> (Wis), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#escape-artist" style={{
      "color": "rgb(87, 158, 182)"
    }}>Escape Artist</a>
(Dex), <a href="skillsAll.html#hide" style={{
      "color": "rgb(87, 158, 182)"
    }}>Hide</a>
(Dex), <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump
    </a>(Str), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a> (Int), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
(religion) (Int), <a href="skillsAll.html#listen" style={{
      "color": "rgb(87, 158, 182)"
    }}>Listen</a> (Wis), <a href="skillsAll.html#move-silently" style={{
      "color": "rgb(87, 158, 182)"
    }}>Move
Silently</a> (Dex), <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="psionicSkills.html#psicraft">Psicraft</a>
(Int), <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense Motive</a> (Wis), <a href="skillsAll.html#spot" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spot</a>
(Wis), <a href="skillsAll.html#tumble" style={{
      "color": "rgb(87, 158, 182)"
    }}>Tumble</a> (Dex).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
4 + Int modifier.</p>
    <a id="table-the-psionic-fist"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Psionic Fist</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "24%"
          }}>Special</th>
          <th>Points/Day</th>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span>
          </td>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Level Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>Monk abilities
          </td>
          <td>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+3</td>
          <td>-
          </td>
          <td>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>-
          </td>
          <td>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>-
          </td>
          <td>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>Bonus psionic feat
          </td>
          <td>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>-
          </td>
          <td>23
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>-
          </td>
          <td>31
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>+6</td>
          <td>-
          </td>
          <td>43
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>-
          </td>
          <td>55
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">Bonus psionic feat
          </td>
          <td className="last-row">71
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psionic-fist-class-features"></a><h6>Class Features</h6>
All the following are class features of the Psionic Fist prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Psionic Fists gain no proficiency with any weapon or armor.</p>
    <a id="psionic-fist-monk-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Monk Abilities:</span> A
Psionic Fist&#8217;s class levels stack with his monk levels for the purpose
of determining his unarmed damage and bonuses to Armor Class and
unarmored speed. His class levels do not apply to other monk abilities
such as flurry of blows, slow fall, and so on.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Power Points/Day:</span> A
Psionic Fist can manifest powers. His ability to manifest powers is
limited by the power points he has available. His base daily allotment
of power points is given on Table: The Psionic Fist. In addition, he
receives bonus power points per day if he has a high Wisdom score (see
Table: Ability Modifiers and Bonus Power Points). His race may also
provide bonus power points per day, as may certain feats and items. If
a Psionic Fist has power points from a different class, those points
are pooled together and usable to manifest powers from either class.
Bonus power points from having a high ability score can be gained only
for the character&#8217;s highest psionic class.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> A
Psionic Fist chooses his powers from the <a href="PsionicWarriorList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic
warrior power list</a>. At
1st level, a Psionic Fist knows one psychic warrior power of your
choice. Each time he attains a new level, he learns one new power. A
Psionic Fist can manifest any power that has a power point cost equal
to or lower than his manifester level. The total number of powers a
Psionic Fist can manifest per day is limited only by his daily power
points.
A Psionic Fist simply knows his powers; they are ingrained in his mind.
He does not need to prepare them (in the way that some spellcasters pre
pare their spells), though he must get a good night&#8217;s sleep each day to
regain all his spent power points.
The Difficulty Class for saving throws against Psionic Fist powers is
10 + the power&#8217;s level + the Psionic Fist&#8217;s Wisdom modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Maximum Power Level Known:</span>
A Psionic Fist gains the ability to learn one 1st-level power when he
takes his first level in the prestige class. As he attains each new
odd-numbered level, a Psionic Fist gains the ability to master more
complex powers.
To learn or manifest a power, a Psionic Fist must have a Wisdom score
of at least 10 + the power&#8217;s level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Psionic Feat:</span> At
5th and 10th level, a Psionic Fist can take any psionic feat as a bonus
feat. He must still meet the prerequisites for the feat.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Multiclass Note:</span> A
monk who becomes a Psionic Fist may continue advancing as a monk. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      